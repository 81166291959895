import React from 'react';
import { Layout } from '../components/Layout';
import { AboutUs } from '../modules/AboutUs';
import { Footer } from '../modules/Footer';
import { Menu } from '../modules/Menu';

const institutionalPages = ({ ...pageData }) => {
  return (
    <Layout title={pageData.pageContext.title}>
      <Menu menuHasTwoColors={false} />
      <AboutUs {...pageData.pageContext} />
      <Footer />
    </Layout>
  );
};

export default institutionalPages;
