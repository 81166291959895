import React from 'react';
import styles from './style.module.css';

interface Props {
  contentTitle: string;
  content: string;
}

export const AboutUs = ({ ...props }: Props) => {
  return (
    <section className={styles.aboutUs}>
      <div className={styles.container}>
        <h1>{props.contentTitle}</h1>
        <div dangerouslySetInnerHTML={{ __html: props.content }} />
      </div>
    </section>
  );
};
